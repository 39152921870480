@use '@angular/material' as mat;
.km-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gray20);
  box-shadow: 0 5px 15px var(--gray80, 0.5);
  z-index: 9;
  @include respond(tab-port) {
    position: fixed;
    top: 0;
    padding-top: 40px;
    left: 0;
    width: 100vw;
    height: 100%;
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &__header {
    font-size: 1.5rem;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    display: flex;
    color: var(--white);
    background-color: var(--primary-800);
    padding: 5px 15px;
    text-align: center;
    &--close {
      cursor: pointer;
      padding: 0.5rem;
      margin-left: auto;
      width: 30px;
    }
    h5 {
      flex: 1 1;
    }
  }
  &__container {
    position: absolute;
    width: calc(100vw - 30px);
    top: 10%;
    background-color: var(--white);
    overflow: hidden;
    left: 2%;
    transform: none;
    margin: auto;
    min-height: 5rem;
    border-radius: 10px;
    // z-index: calc(#{$roof} + 2);
    //background-color: mat-color($-palette, dialog);
    @include respond(tab-port) {
      left: 50%;
      transform: translateX(-50%);
      width: 60%;
    }
  }
  &__body {
    overflow-y: auto;
    max-height: 75vh;
    position: relative;
    .mat-form-field {
      width: 100%;
    }
  }
}

.modal-form {
  display: flex;
  flex-flow: column;
  label {
    font-size: 14px;
    color: var(--gray30);
  }
}
.modal-backdrop {
  background-color: transparent !important;
  height: auto;
}
// .primary-btn {
//   color: mat-color($app-accent, 100);
//   background-color: mat-color($app-primary, 600);
//   border: 1px solid mat-color($app-primary, 600);
//   border-radius: 5px;
//   padding: 6px 20px;
//   font-size: 14px;
//   text-transform: uppercase;
//   &:hover {
//     background-color: mat-color($app-primary, 500);
//   }
// }
