@mixin app-session-expired($theme) {
  .app-session-expired {
    .session-main-box {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;

      &__box {
        flex-basis: 50%;
        text-align: center;
      }
    }
  }
}
