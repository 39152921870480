// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import './primary-palette';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

/* You can add global styles to this file, and also import other style files */

$theme-colors: (
  'primary': mat.get-color-from-palette($app-primary, 500),
  'secondary': mat.get-color-from-palette($app-accent, 800),
  'success': mat.get-color-from-palette($app-accent, 900),
  'warning': mat.get-color-from-palette($app-accent, A200),
  'danger': mat.get-color-from-palette($app-accent, 500),
  'text-color': mat.get-color-from-palette($app-accent, 100),
  'primary-50': mat.get-color-from-palette($app-primary, 50),
  'primary-300': mat.get-color-from-palette($app-primary, 300),
  'primary-400': mat.get-color-from-palette($app-primary, 400),
  'primary-600': mat.get-color-from-palette($app-primary, 600),
  'primary-700': mat.get-color-from-palette($app-primary, 700),
  'primary-800': mat.get-color-from-palette($app-primary, 800),
  'primary-900': mat.get-color-from-palette($app-primary, 900),
  'primaryA-700': mat.get-color-from-palette($app-primary, A700),
  'primaryG-100': mat.get-color-from-palette($app-primary, G100),
  'primaryG-200': mat.get-color-from-palette($app-primary, G200),
  'primaryG-300': mat.get-color-from-palette($app-primary, G300),

  'gray20': mat.get-color-from-palette($app-accent, 200),
  'gray30': mat.get-color-from-palette($app-accent, 300),
  'gray40': mat.get-color-from-palette($app-accent, 400),
  'gray50': mat.get-color-from-palette($app-accent, A300),
  'gray60': mat.get-color-from-palette($app-accent, 600),
  'gray80': mat.get-color-from-palette($app-accent, 700),
  'yellow': mat.get-color-from-palette($app-accent, A100)
);

// @import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap/scss/bootstrap.scss';
