@mixin app-question($theme) {
  .app-question {
    height: 100%;
    .quiz-question-wrapper {
      display: flex;
      flex-flow: column;
      text-align: left;
      height: 100%;

      .action-buttons {
        display: inline-flex;
        flex-basis: 100%;
        margin-top: 10px;
        @include respond(tab-land) {
          flex-basis: 20%;
          margin-top: unset;
        }
        justify-content: space-between;
      }

      .question-answer-div {
        height: auto;
        @include respond(large-phone) {
          height: 85%;
        }
        .question-title {
          color: mat-color($app-primary-palette, 500);
          font-size: 18px;
        }
        display: flex;
        flex-flow: row wrap;
        padding: 10px 10px;
        .question-div,
        .answer-div {
          width: 100%;

          @include respond(tab-port) {
            // width: 50%;
          }

          .quiz-img {
            display: flex;
            justify-content: flex-end;
            img {
              width: 110px;
            }
          }
        }

        .question-div {
          @include respond(tab-port) {
            flex: 1 1 50%;
            max-width: 60%;
          }
          overflow-x: auto;
          // padding-top: 10px;
          .question-title {
            word-break: break-word;
            padding: 0 10px;
            text-align: center;
            font-size: 16px;
            max-height: 280px;
            overflow-y: auto;
          }
        }

        .answer-div {
          width: 40%;
          .question-choices-single,
          .question-choices-multiple {
            padding: 0 10px;
            height: auto;
            @include respond(large-phone) {
              max-height: 216px;
            }
            overflow: auto;
          }

          .question-choices-multiple {
            .question-choices {
              padding: 12px;
            }
          }

          .question-choices {
            background: mat-color($app-primary-palette, default-contrast);

            border: 1px solid mat-color($app-primary-palette, 300);
            margin: 4px 0px;
            border-radius: 4px;

            .mat-radio-label {
              .mat-radio-label-content {
                margin-left: 10px;
              }
            }

            .mat-checkbox-layout {
              &-inner-container {
                margin-left: 0px;
              }
            }
          }
          .question-choices:hover {
            border: 1px solid mat-color($app-primary-palette, 500);
            background: mat-color($app-primary-palette, 300);
          }
        }
      }

      .mat-checkbox {
        display: flex;
      }

      .mat-radio-button {
        margin-top: 3%;
      }

      .actions {
        background-color: mat-color($app-primary-palette, 300);
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        @include respond(tab-land) {
          justify-content: space-around;
        }
        padding: 20px 10px;
        align-items: center;

        .previous {
          flex-basis: 20%;
          margin-bottom: 10px;
          @include respond(large-phone) {
            margin-bottom: 0px;
          }

          .previous-button {
            cursor: pointer;
            background-color: unset;
            border: 0px;
            padding: 0px;
          }
        }
      }

      .question-div {
        background-size: cover;
        background-image: url('./../../../../../../assets/images/question.jpg');
        background-repeat: round;
        display: flex;
        flex-flow: column wrap;
        min-height: 185px;
        @include respond(tab-land) {
          min-height: 280px;
        }
        justify-content: center;
      }
      .answer-div {
        display: flex;
        justify-content: space-around;
        flex-flow: column wrap;

        .question-choices-single,
        .question-choices-multiple {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          margin-top: 1%;
        }

        .question-choices-single {
          .mat-radio-group {
            flex-direction: column;
            display: flex;
          }
        }
      }
    }
  }
}
