@import './../../../../scss/theme/theme-palettes';
@import '../quiz/quiz.component.scss';

@mixin app-quiz-list($theme) {
  @include app-quiz($theme);

  .app-quiz-list {
    .lms-dashboard {
      padding: 0px 15px;

      .dashboard-header {
        span {
          font-weight: 600;
          font-size: 24px;
        }

        .mat-headline {
          margin: 0;
        }
        .sub-heading {
          margin-top: 10px;
          padding: 10px;
          background-color: mat-color($app-primary-palette, 500);
          color: mat-color($app-primary-palette, 300);
        }
      }
      .dashboard-content {
        margin-top: 2%;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
          0px 0px 0px 0px rgba(0, 0, 0, 0.12);

        .quiz-list-wrapper {
          background: mat-color($app-primary-palette, default-contrast);
          padding: 6px 20px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          font-size: 13px;
          font-weight: 500;

          .qz-bold {
            font-weight: 600;
          }

          .quiz-title {
            flex-basis: 60%;
            padding: 0px 4px;
          }

          .quiz-question-count {
            flex-basis: 15%;
            text-align: center;
            padding: 0px 4px;
            color: rgba(0, 0, 0, 0.38);
          }
          .quiz-time {
            flex-basis: 15%;
            text-align: center;
            padding: 0px 4px;
            color: rgba(0, 0, 0, 0.38);
          }
          .quiz-list-action {
            flex-basis: 10%;
            text-align: right;
            padding: 0px 4px;
          }
        }
        .list-wrapper-heading {
          padding: 13px 20px;
          font-size: 16px;
        }
      }
    }
  }
}
::ng-deep {
  .quiz-dialog-modal {
    max-width: 95vw !important;
  }
}
