@use '@angular/material' as mat;
@import '../../../../../../scss/combine';
.add_div {
  border: 1px solid var(--primary-700);
  border-radius: 10px;
  // padding: 20px 0px;
  // width: 18%;
  height: 120px;
  // flex: 1 1 45%;
  &:hover {
    background-color: var(--primary-700);
    .add_button,
    .theme_icon {
      color: var(--white);
    }
  }
  @include respond(tab-port) {
    // flex: 0 1 18%;
  }
  cursor: pointer;

  .theme_icon {
    color: var(--primary-700);
    font-size: 26px;
    margin-bottom: 15px;
  }
  .add_button {
    background: none;
    border: none;
    text-transform: capitalize;
    color: var(--primary-700);
    font-weight: 500;
    font-size: 18px;
    &:focus-visible {
      outline: none !important;
    }
  }
}
@mixin user-list($theme) {
  .tabs {
    .mat-tab-label {
      border-top: 1px solid var(--gray80);
      border-left: 1px solid var(--gray80);
      padding: 25px 0px;
      // font-family: 'Quicksand', sans-serif;
      &:last-child {
        border-right: 1px solid var(--gray80);
      }
    }
  }
}

.mat-tab-group.mat-primary .mat-ink-bar {
  border-bottom: 3px solid var(--primary-700);
}

.tabs .mat-tab-label {
  border-bottom: 3px solid transparent;
  &:hover {
    border-bottom: 3px solid var(--primary-700);
    opacity: 1;
    color: var(--dark);
  }
}

.tabs .mat-tab-label-active {
  opacity: 1;
  .mat-tab-label-content {
    font-weight: 800;
    color: var(--primary-700);
  }
}

.container {
  .content h2 {
    font-size: 28px !important;
    margin-bottom: 0px;
  }
  a {
    text-decoration: none;
  }
  .grid-icon {
    font-size: 45px !important;
  }
  .h4 {
    font-size: 16px;
  }
}

.c-h {
  height: 25px;
}
.below-dropdown {
  margin-top: 30px !important;
}
.no-content-found {
  text-align: center;
  font-weight: 600;
}
.checkbox-input {
  position: absolute;
  right: 16px;
  top: -3px;
}
.clear-input {
  position: absolute;
  right: 20px;
  top: -3px;
}

.content h2 {
  font-size: 35px;
}

.bg-1 {
  background-color: var(--primary-400);
  color: var(--text-color);
  justify-content: space-between;
  .grid-icon {
    font-size: 60px;
    width: 55px;
    height: 36px;
  }
}

.bg-2 {
  background-color: var(--primary-600);
  color: var(--text-color);
  justify-content: space-between;
  .grid-icon {
    font-size: 60px;
    width: 50px;
    height: 40px;
  }
}

.bg-3 {
  background-color: var(--primary-800);
  color: var(--text-color);
  justify-content: space-between;
}

input:disabled + .slider {
  background: var(--primary-300) !important;
}
.customEllipsis{
  white-space: nowrap;      /* Prevent text from wrapping to the next line */
  overflow: hidden;         /* Hide any overflowed text */
  text-overflow: ellipsis; /* Display an ellipsis (...) when text overflows */
  max-width: 100%;
  margin-top: 0;           /* Remove top margin */
  margin-bottom: 0;       /* Adjust max-width if needed */
}
