@import './../../../../scss/mixins';
@mixin app-quiz-details($theme){
  .quiz-details-title{
    padding: 0px;
    
  .quiz-details-title-header{
      // margin-top: 10px !important;
      background: #0a5f75;
      color: white;
   
      &-box-img{    width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
      background: #65858e;}
   
      .quiz-details-title-header-box-input{
        width: 100%;
      }
      // border-left: 1px solid #4993a7;
      .input_gradient {
        width: 90%;
        background: #0a5f75;
        color: white;
        padding: 20px;
        font-size: 20px;
        border: 1px solid rgba(75, 205, 241, 0);
        &:focus{
          outline: none;
        }
       
      }
      .input_gradient::placeholder{color:#c1c1c1;}
  
  }
  }
  .mar-fields{
      margin-bottom: 40px;
      overflow: hidden;
  }
  
  
  
  .submit-btn-postion{
      display: flex;
      justify-content: center;
      margin-bottom:20px;
  
      .btn-position-back,.btn-position-upload{font-weight: 600; font-size: 12px;}
     
    }
  
    .mar-left-btn{
        margin-left: 5px;
    }
  
    .admin-quiz-title{
      display: inline-block;
      color:#585858;
      font-size: 30px;
      margin-bottom: 10px;
    }
  
    .back-btn{
       margin-right: 1%;
    }
  
    .titleGradient{
      width: 90%;
      float: left;
      height: 110px;
      border-radius: 6px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      background: #52e2f8;
      color: white;
      border: 1px solid rgba(75, 205, 241, 0);
      background: #0a5f75;
      color: white;
      padding: 20px;
      font-size: 20px;
      border-left: 1px solid #4993a7;
    }
  
    .quiz-details{
      overflow: auto;
      height: 100%;
    }
  
    .quiz-details-content{
      margin:0 auto;
      margin-top: 5px;
      width: 85%;
      height: 100%;
  
      .quiz-details-content-back-btn{position:absolute; left: 48px; top:14px;}
    }
  
    .options-content{
      padding-top: 10px;
        font-size: 12px;
    .shuffle-que{display: flex; justify-content: flex-start; flex-flow:row wrap; align-items: center; }
  
    .quiz-time-range{
      width: 3%;
      margin-left: 1%;
    }
  
    .mat-grid-tile-left{
      figure{
        margin-left: 12px !important;
        justify-content: flex-start !important;
      }
    }
  }
  
  .ic-left-arrow {
    background: url('./../../../../assets/images/backButton.png') no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
    position: relative;
    top: 14px;
    right: 5px;
    cursor: pointer;
  }
  
  .quiz-details-heading {
    line-height: 40px;
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
  }
}



