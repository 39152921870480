// @import '../../../scss/mixins';
// @import '../../../scss/theme/theme-palettes';
@import "./result/result.component.scss";
@import "./question/question.component.scss";

@mixin app-quiz($theme) {
  @include app-quiz-result($theme);
  @include app-question($theme);
  .app-quiz {
    height: 100%;

    .dialog-sub-header {
      height: 6%;
      padding: 10px 0px 10px 0px;
      .timer {
        padding: 0 10px;
        text-align: right;
        color: mat-color($app-primary-palette, 500);
      }
    }

    .sub-heading {
      background-color: unset;
      color: mat-color($app-primary-palette, 500);

      .quiz-title {
        font-weight: 500;
        font-size: 20px;
        padding: 10px;
      }
    }

    .quiz-dialog {
      height: 100%;
      .mat-dialog-title {
        margin: 0px;
        padding-top: 2px;
      }

      .close-icon {
        cursor: pointer;
        padding-top: 8px;
      }

      .dialog-header {
        height: 10%;
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: mat-color($app-primary-palette, 500);
        color: mat-color($app-primary-palette, 300);
        align-items: center;
      }
      .dialog-sub-header {
        height: 6%;
        padding: 10px 0px 10px 0px;
        .timer {
          padding: 0 10px;
          text-align: right;
          color: mat-color($app-primary-palette, 500);
        }
      }

      .dialog-content {
        height: 80%;
        .question {
          height: 100%;

          .previous {
            flex-basis: 20%;
            margin-bottom: 10px;
            @include respond(large-phone) {
              margin-bottom: 0px;
            }

            .previous-button {
              cursor: pointer;
              background-color: unset;
              border: 0px;
              padding: 0px;
            }
          }
          .question-count {
            flex-basis: 50%;
            color: mat-color($app-primary-palette, 100);
            margin-bottom: 10px;
            @include respond(tab-land) {
              margin-bottom: 0px;
              text-align: center;
            }
            text-align: right;
          }
          .action-buttons {
            display: inline-flex;
            flex-basis: 100%;
            margin-top: 10px;
            @include respond(tab-land) {
              flex-basis: 20%;
              margin-top: unset;
            }
            justify-content: space-between;
          }

          .action-submit {
            display: inline-flex;
            flex-basis: 100%;
            @include respond(large-phone) {
              flex-basis: 25%;
            }
            justify-content: flex-end;
          }
        }
      }
    }
    .quiz-error-container {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;

      .quiz-error-box {
        flex-basis: 50%;
        text-align: center;
      }
    }
  }
}
