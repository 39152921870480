@mixin app-quiz-result($theme) {
  .app-quiz-result {
    .mat-headline {
      margin: 0;
    }

    .content-result {
      display: flex;
      justify-content: space-around;
      flex-flow: column wrap;
      @include respond(large-phone) {
        flex-flow: row wrap;
      }
      padding: 30px 0px;

      .result-img {
        flex-basis: 100%;
        @include respond(large-phone) {
          flex-basis: 40%;
        }

        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        img {
          max-width: 100%;
        }
      }

      .score {
        flex-basis: 100%;
        @include respond(large-phone) {
          flex-basis: 40%;
        }

        margin-bottom: 20px;
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        background: mat-color($app-primary-palette, 300);

        .yts {
          display: inline-flex;
          justify-content: center;
          align-items: center;

          .yts-box {
            padding: 10px;
            text-align: right;
            color: mat-color($app-primary-palette, 500);

            h2 {
              font-weight: 600;
              text-align: center;
              margin: 0px;
              font-size: 40px;
              line-height: 40px;
            }
            span {
              font-weight: 400;
              font-size: 16px;
            }

            h3 {
              font-weight: 400;
              margin: 0px;
              span {
                color: mat-color($app-primary-palette, 400);
              }
            }
          }
        }
      }
    }

    .score {
      min-height: 200px;
      padding: 20px;

      .status {
        color: mat-color($app-primary-palette, 400);
        h3 {
          font-weight: 400;

          .material-icons {
            color: mat-color($app-primary-palette, 500);
          }
        }
      }

      .total-score {
        h2 {
          font-weight: 600;
          color: mat-color($app-primary-palette, 500);
          span {
            color: mat-color($app-primary-palette, 500);
          }
        }
      }

      .percentage {
        h3 {
          font-weight: 600;
          color: mat-color($app-primary-palette, 400);
          span {
            color: mat-color($app-primary-palette, 500);
          }
        }
      }
    }

    .quiz-sub-heading {
      background-color: unset;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: mat-color($app-primary-palette, 500);

      .mat-title {
        margin: 0px;
        padding: 10px 16px 10px 10px;
      }

      .mat-flat-button {
        color: mat-color($app-primary-palette, 300);
        background: transparent;
        text-decoration: underline;
      }
    }
  }
}
