@import './../../../../scss/mixins';
@mixin app-manage-quiz($theme){
    h4{color: #333;}
    $s-color : var(--s-color);
    $p-color :rgba(0, 0, 0, 0.7);
    
    .manage-sub-title{
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #333;
       }
    .flex-container-four{display: flex; 
        flex-flow: column wrap;
        @media screen and (min-width:481px){
            flex-flow: row wrap;
            
        } 
        justify-content: flex-start; 
        margin:20px 0px;
    
        .flex-box-four{
        flex-basis: 23%;
        border: 1px solid var(--s-color);
        border-radius: 8px;
        padding: 25px 15px;
        box-sizing: border-box;
        display: inline-flex;
        flex-direction: column;
        margin-bottom: 20px;
        margin-right: 20px;
        min-height: 140px;
    
        span{
            font-size: 11px;
            color: #aaa9a9;
            margin: 6px 0px;
            font-weight: bold;
        }
    
        label{
            color: var(--s-color);
            font-weight: 700;
            padding: 0px;
            margin-top: 20px;
            margin-bottom: 0px;
            font-size: 12px;
        }
    
        p{
            margin: 0px;
            color: var(--s-color);
            font-size: 14px;
            font-weight: 500;
        }
    
        &:hover {
            background: var(--s-color);
            color: var(--white-color);
    
            span {
                color: var(--white-color);
            }
    
            label,p {
                color: var(--white-color);
            }
        }
    
        &.active{
            background: var(--s-color);
            color: var(--white-color);
            span {
                color: $p-color;
            }
            p,label {
                color: var(--white-color);
            }
        }
        cursor: pointer; 
    }
    }
    
    .manage-quiz-content{
        margin:0 auto;
        width: 85.2%;
        height: 100%;
    
        h1,h2{
            color:#585858;
            margin-bottom: 10px;
        }
    }
    
    a {
        align-self: auto;
        color: inherit; 
        padding:10px;
    } 
    
    mat-panel-title{
        padding:10px;
    }
    
    .actionButtons{
        justify-content: flex-end;
    }
    .mr-icons{
        margin-top: 5px;
        margin-right: 24px;
    }
    
    
    .list-item{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .list-title{
            padding: 0px 10px 10px 10px;
        }
        // .list-buttons{
        //     padding: 10px;
        // }
        
        .list-title{flex-basis: 90%;
        h4{font-size:18px;margin-bottom: 5px; font-weight: 500;line-height: 1.1;color:#333}
        }
        p{
            font-size: 12px !important;
            font-weight: 700 !important;
            color: var(--s-color);
            text-decoration: underline solid var(--s-color);
        }
        .title-textancr{font-size: 12px !important;}
        .actionButtons {
    
            // display: flex;
            a{
                color: var(--s-color);
                width: 40px;
            }
            // a:hover{opacity: 0.7;}
        }
    }
    
    .ic-edit-black-draft {
        position: absolute;
        margin-left: -18px;
        background: url('./../../../../assets/images/edit-black.png');
        display: inline-block;
        width: 26px;
        height: 26px;
    }
    
    .ic-delete-black-draft {
        position: absolute;
        background: url('./../../../../assets/images/delete-black.png');
        display: inline-block;
        width: 26px;
        height: 26px;
    }
    
    .ic-edit-black-pending {
        margin-left: -18px;
        background: url('./../../../../assets/images/edit-black.png');
        display: inline-block;
        width: 26px;
        height: 26px;
    }
    
    .ic-delete-black-pending {
        background: url('./../../../../assets/images/delete-black.png');
        display: inline-block;
        width: 26px;
        height: 26px;
    }
    
    .ic_undo_icn {
        right: 35px;
        position: absolute;
        background-size: 24px;
        background-repeat: no-repeat;
        background: url('./../../../../assets/images/undo.png');
        display: inline-block;
        width: 26px;
        height: 26px;
        cursor: pointer;
    }
    
    .border-spacing{border-bottom: 1px solid #aaa9a9;
        line-height: 42px; margin-bottom: 20px;}
    
        .recently-accessed{
     .mat-divider {
                border-top-color: rgba(0, 0, 0, 0.3);
            }
        }
    
        .quiz-description{
            line-height: 36px;
            font-size: 16px;
            font-weight: 500;
            color:#333;
        }
    
    
            mat-expansion-panel{
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            }  


            .no-content{
                text-align: center;
                h2{
                    font-weight: 500;
                    margin-top:30px;
                    font-size:30px;
                }
            }
}
