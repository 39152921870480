// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   src: local('Roboto'), local('Roboto-Regular'), url(../assets/fonts/roboto-400.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   src: local('Roboto Medium'), local('Roboto-Medium'), url(../assets/fonts/roboto-500.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   src: local('Roboto Bold'), local('Roboto-Bold'), url(../assets/fonts/roboto-700.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

/* latin */
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 300;
  src: local("Quicksand Light"), local("Quicksand-Light"),
    url(../assets/fonts/quicksand-300.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  src: local("Quicksand Medium"), local("Quicksand-Medium"),
    url(../assets/fonts/quicksand-500.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  src: local("Quicksand Bold"), local("Quicksand-Bold"),
    url(../assets/fonts/quicksand-700.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/material-fonts-icons.woff2) format("woff2");
}

// @font-face {
//   font-family: "Titillium Web";
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(../assets/fonts/titilium-regular.woff2) format("woff2");
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
//     U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
/* latin */
// @font-face {
//   font-family: "Titillium Web";
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(../assets/fonts/titilium-regular-latin.woff2) format("woff2");
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
//     U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
//     U+FEFF, U+FFFD;
// }

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
