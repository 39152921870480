@import "./create-quiz/create-quiz.component.scss";
@import "./quiz-details/quiz-details.component.scss";
@import "./manage-quiz/manage-quiz.component.scss";

@mixin app-admin-dashboard($theme) {
  .app-admin-dashboard {
    .admin-dashboard {
      @include app-create-quiz($theme);
      @include app-quiz-details($theme);
      @include app-manage-quiz($theme);
      // margin: 2%;
      .side-container {
        .mat-drawer {
          .mat-drawer-inner-container {
            display: flex;
            justify-content: space-between;
            overflow: auto;
            flex-direction: column;
          }
        }
      }

      .mat-grid-tile-left {
        figure {
          margin-left: 12px !important;
          justify-content: flex-start !important;
        }
      }

      .mat-card {
        border: 1px solid var(--s-color);
        border-radius: 10px !important;
        box-shadow: none !important;
      }

      .quiz-details-mat-card {
        border: 1px solid #aaa9a9;
        border-radius: 0px !important;
        box-shadow: none !important;
        color: #000;
      }

      // .mat-tab-label{
      //   &:hover{
      //   background: var(--s-color);
      //   box-sizing: border-box;
      //   color: var(--white-color);
      //   opacity: 1;
      //   }
      // }

      .quiz-title-header {
        .mat-card-header-text {
          width: 100%;
          .mat-card-title {
            .mat-form-field {
              width: 100%;
            }
          }

          .mat-card-subtitle {
            .mat-form-field {
              width: 100%;
            }
          }
        }
      }

      .options-content {
        .mat-grid-list {
          margin-left: -4px;
          .mat-grid-tile {
            .mat-checkbox {
              .mat-checkbox-inner-container {
                .mat-checkbox-frame {
                  border: 1px solid #dadada;
                  background: #eceaea;
                  border-radius: 4px;
                }
              }
              .mat-checkbox-label {
                font-size: 12px;
                font-weight: normal;
              }
            }
            .mat-checkbox-inner-container:focus {
              border-radius: 4px;
            }
          }
        }

        .mat-slide-toggle {
          margin-right: 30px;
        }
      }

      .quiz-details-title {
        border-radius: 6px;
        background: var(--s-color);
        .quiz-details-title-header {
          .mat-card-header-text {
            width: 0%;
          }
        }
      }

      .library {
        .mat-tab-header {
          .mat-tab-label {
            opacity: 1;
            height: 60px;
            color: #555;
            padding: 18px 30px !important;
            border-left: 1px solid #ddd;
            border-top: 1px solid #ddd;
            border-bottom: 2px solid #fff;
            &:hover {
              border-bottom-color: var(--s-color);
            }

            &:active {
              font-weight: bold;
            }

            &:focus {
              font-weight: bold;
            }
          }
          .mat-tab-label:nth-last-child(1) {
            border-right: 1px solid #ddd;
          }
        }
        .quiz-description {
          h4 {
            font-weight: 500;
            margin: 0px;
            padding: 0px 23px;
            font-size: 16px;
            line-height: 36px;
            margin-bottom: 10px;
            margin-top: 10px;
          }
        }
        .mat-tab-body-wrapper {
          .mat-action-row {
            justify-content: center;
          }

          .mat-tab-body-content {
            .mat-content {
              .actionButtons {
                a {
                  color: var(--s-color);
                }
              }
            }
          }
        }

        .manage-quiz-approve {
          .mat-list-item {
            height: auto;
          }
        }

        .quiz-delete-tab,
        .quiz-draft-tab {
          .mat-list-item {
            .mat-list-item-content {
              .list-title {
                h4 {
                  margin: 0px;
                  font-weight: 500;
                }
                p {
                  margin: 0px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .questionTitle {
        width: 95%;
        .mat-form-field-label {
          padding: 5px 0 5px 0;
        }
      }

      .footer-sidebar {
        .quiz-save-btn,
        .btn-draft {
          border-radius: 0px;
          font-weight: 600;
          padding: 2px 10px;
          border: 1px solid #0000001a;
        }
        .btn-draft:hover {
          background: #eaeaea;
        }
        .quiz-save-btn:hover {
          background: #22768b;
        }
      }

      .custom-hover-btn:hover {
        background: #22768b;
      }

      .mat-card-header-text {
        display: none;
      }
      .item-draft-new {
        height: 80px !important;
      }

      ::-webkit-scrollbar {
        width: 6px;
        height: 4px;
      }

      /* Track */

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 0px;
      }

      /* Handle */

      ::-webkit-scrollbar-thumb {
        background: var(--s-color);
        border-radius: 0px;
      }

      /* Handle on hover */

      ::-webkit-scrollbar-thumb:hover {
        background: var(--s-color);
      }
    }
  }
}
