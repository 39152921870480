// @import "variables";

/**
Breakpoints:
600px - 900px tab-port
900px - 1200px tab-land
1200px - above desktop
*/

@mixin respond($breakpoint) {
  $device-sizes: (
    large-phone: 480px,
    tab-port: 768px,
    tab-land: 900px,
    desktop: 1200px,
  );
  @if $breakpoint==large-phone {
    @media screen and (min-width: map-get($device-sizes, large-phone)) {
      @content;
    }
  }
  @if $breakpoint==tab-port {
    @media screen and (min-width: map-get($device-sizes, tab-port)) {
      @content;
    }
  }
  @if $breakpoint==tab-land {
    @media screen and (min-width: map-get($device-sizes, tab-land)) {
      @content;
    }
  }
  @if $breakpoint==desktop {
    @media screen and (min-width: map-get($device-sizes, desktop)) {
      @content;
    }
  }
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin box-shadow($elevation) {
  @if $elevation == 1 {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
      0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  } @else if $elevation == 2 {
    box-shadow: 0px 0px 76px 0px rgba(0, 0, 0, 0.19);
  }
}

//spacing

$spacer: 1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
  0: (
    x: 0,
    y: 0,
  ),
  h: (
    x: (
      $spacer-x * 0.5,
    ),
    y: (
      $spacer-y * 0.5,
    ),
  ),
  1: (
    x: $spacer-x,
    y: $spacer-y,
  ),
  2: (
    x: (
      $spacer-x * 1.5,
    ),
    y: (
      $spacer-y * 1.5,
    ),
  ),
  3: (
    x: (
      $spacer-x * 3,
    ),
    y: (
      $spacer-y * 3,
    ),
  ),
  4: (
    x: (
      $spacer-x * 4,
    ),
    y: (
      $spacer-y * 4,
    ),
  ),
);

@mixin spacing() {
  @each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $lengths in $spacers {
      $length-x: map-get($lengths, x);
      $length-y: map-get($lengths, y);

      .#{$abbrev}-#{$size} {
        #{$prop}: $length-y $length-x !important;
      } // a = All sides
      .#{$abbrev}t-#{$size} {
        #{$prop}-top: $length-y !important;
      }
      .#{$abbrev}r-#{$size} {
        #{$prop}-right: $length-x !important;
      }
      .#{$abbrev}b-#{$size} {
        #{$prop}-bottom: $length-y !important;
      }
      .#{$abbrev}l-#{$size} {
        #{$prop}-left: $length-x !important;
      }
    }
  }
}
