@import '../../../../scss/mixins';
@mixin app-create-quiz($theme) {
  html,
  body {
    height: 100%;
    margin: 0;
  }

  .side-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;

    .mat-drawer {
      .mat-drawer-inner-container {
        display: flex;
        justify-content: space-between;
        overflow: auto;
        flex-direction: column;
      }
    }
    .container {
      width: 87.5%;
      margin: auto;

      .content-main-heading {
        margin-bottom: 10px;

        h2 {
          font-size: 30px;
          color: #585858;
          margin: 10px 0px;
        }

        .quiz-format {
          padding: 10px;
          margin: 0px;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .remove-btn-postion {
    position: absolute;
    top: -10px;
    right: -10px;
  }

  .edit-btn-postion {
    position: absolute;
    top: -10px;
    right: 35px;
  }

  .questionBox {
    margin: 50px;
    width: auto;
    label,
    input,
    .questionNo {
      color: #585858;
      font-size: 18px;
    }
    overflow-wrap: break-word;
  }

  .questionContent {
    padding: 20px;
    .mat-card-title {
      overflow: hidden;
    }

    span {
      img {
        width: 50%;
      }
    }
    .mat-card-content {
      div {
        .mat-radio-group {
          .mt-radio-true-false {
            .true-false-pd {
              padding: 10px;
            }
          }
        }
      }
    }
  }

  .main-sidebar {
    min-height: 81.2%;

    .main-sidebar-title {
      display: flex;
      justify-content: center;
      margin: 20px 0px 30px 0px;
    }

    .sidebar_button-container {
      display: flex;
      justify-content: space-between;
      min-height: 250px;
      flex-direction: column;
      padding: 0px 30px;
    }
  }

  /* .footer-sidebar {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
    overflow: hidden;
} */

  .footer-sidebar {
    margin-top: -15px;
    .btn-draft {
      color: #585858;
      width: 50%;
    }

    .primary:hover {
      background: #fff;
    }
  }

  .quiz-save-btn {
    width: 50%;
  }

  mat-form-field {
    width: 90%;
  }

  .side-button-grid {
    margin-left: 10px;
  }

  .options-box-rld {
    display: flex;

    .options-radio-button {
      .options-label {
        display: block;
        white-space: normal;
        word-break: break-word;
      }
      padding: 10px;
    }
  }

  .quiz-title-box {
    text-align: center;
    width: 90%;
    margin: auto;
    margin-top: 70px;

    h2 {
      font-size: 30px;
      font-weight: 500;
      color: #333333;
      margin: 10px 0px;
    }

    h5 {
      font-size: 14px;
      color: #333333;
      margin: 0px;
      font-weight: 500;
    }
  }
  .add-btn-postion {
    display: flex;
    justify-content: center;
    padding: 5% 0 5% 0;
  }

  .sidebar-toggle-btn-postion {
    position: absolute;
    top: 50%;
    right: -10px;
  }

  .sideBarToggle-line {
    position: absolute;
    width: 2px;
    height: 35px;
    background-color: rgba(250, 250, 250, 0.8);
    left: 15px;
    z-index: 99;
    top: 13px;
  }

  .mar-left {
    margin-left: 3px;
  }

  a {
    color: inherit;
  }

  .side-header-border-botton {
    border-bottom: 4px solid #0a5f75;
    height: auto;
    .sidebar-header-bar {
      background: #ffffff;

      .side-header {
        text-align: center;
        width: 100%;
        color: #585858;
        font-size: 14px;
      }
    }
  }

  .add-option-btn {
    margin-left: 1%;
  }

  .mat-divider {
    border-bottom: 1px solid #dddddd;
  }

  .ic-delete_quiz {
    background: url('../../../../assets/images/delete26x26_white.png') no-repeat;
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    background-size: 22px;
    background-position: center;
    z-index: 2;
  }

  .ic-edit_quiz {
    background: url('../../../../assets/images/edit_white.png') no-repeat;
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    background-size: 22px;
    background-position: center;
    z-index: 2;
  }

  .sidenav-inner-container {
    width: 387px;
  }
}

.create-quiz-main-content {
  overflow-x: hidden;
}

::ng-deep {
  .note-modal-backdrop {
    z-index: 0;
  }
}

.note-btn {
  span {
    color: black;
    font-weight: bold;
  }
}
