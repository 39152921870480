/* For use in src/lib/core/theming/_palette.scss */
$app-primary: (
  50: #e2ecee,
  100: #b6cfd6,
  200: #85afba,
  300: #548f9e,
  400: #2f778a,
  500: #0a5f75,
  600: #09576d,
  700: #074d62,
  800: #054358,
  900: #033245,
  A100: #7ad2ff,
  A200: #47c0ff,
  A400: #14afff,
  A700: #00a4f9,
  G100: #e2ffdc,
  G200: #ffebdc,
  G300: #d7fff9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff
  )
);

$app-accent: (
  //use app-accent 100 for text-color
  100: #fff,
  200: #eee,
  300: #333,
  400: #666666,
  500: #993232,
  600: #808385,
  700: #ccc,
  800: #585858,
  900: #69885d,
  A100: #f8ea2b,
  A200: #f8992b,
  A300: #e6e6e6,
  contrast: (
    100: #000,
    500: #fff,
    700: #fff
  )
);
