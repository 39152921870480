@use '@angular/material' as mat;
@import '../app/dashboard/components/user/pages/user-list/user-list.component.scss';
@include user-list($theme);

/** Tabs Styling**/

.tabs {
  .mat-tab-label {
    border-top: 1px solid var(--gray80);
    border-left: 1px solid var(--gray80);
    padding: 25px 15px;
    // font-family: "Quicksand", sans-serif;
    &:last-child {
      border-right: 1px solid var(--gray80);
    }
  }
  .mat-tab-body-content {
    overflow: hidden;
    // font-family: "Quicksand", sans-serif;
  }
}

// .mat-checkbox-label {
//   // font-family: "Quicksand", sans-serif;
// }

// .mat-select-trigger {
//   // font-family: "Quicksand", sans-serif;
// }

.mat-form-field {
  // font-family: "Quicksand", sans-serif !important;
  font-size: 14px;
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          // font-family: "Quicksand", sans-serif;
          font-size: 14px;
        }
      }
    }
  }
}
.border-primary {
  border: 1px solid var(--primary-600) !important ;
}
// .bg-primary {
//   background-color: var(--primary-600); !important;
// }

.primary-btn {
  color: var(--text-color) !important;
  background-color: var(--primary-600) !important;
  border: 1px solid var(--primary-600);
  cursor: pointer;
  border-radius: 0px;
  padding: 6px 20px;
  font-size: 14px;
  // font-family: "Quicksand", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  &:hover:enabled {
    background-color: var(--primary-900);
    color: var(--white);
  }
}

.secondary-btn {
  color: var(--gray30);
  background-color: var(--gray20);
  border: 1px solid var(--gray30);
  border-radius: 0;
  padding: 6px 20px;
  font-size: 14px;
  // font-family: "Quicksand", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  &:hover {
    border-color: var(--dark);
    background-color: var(--gray80);
  }
}

.gap-1 {
  gap: 1rem;
}

.theme_icon {
  color: var(--primary-700);
  font-size: 24px;
  margin-bottom: 15px;
  cursor: pointer;
}

.theme_color_icon {
  color: var(--primary-700);
  font-size: 24px;
  cursor: pointer;
}


.circle_icon {
  border: 2px solid var(--gray30);
  border-radius: 50px;
  padding: 4px;
  display: flex;
}

// .mat-slide-toggle {
//   .mat-slide-toggle-bar {
//     border-radius: 34px;
//     width: 50px;
//     height: 26px;
//     // background-color: mat-color($app-primary, 600) !important;
//     .mat-slide-toggle-thumb-container {
//       top: 3px;
//       // transform: translate3d(26px, 0, 0) !important;
//       .mat-slide-toggle-thumb {
//         // background-color: mat-color($app-accent, 100) !important;
//       }
//     }
//   }
// }
.mat-chip-list {
  .mat-chip-list-wrapper {
    .mat-chip.mat-standard-chip {
      background-color: transparent;
      border: 1px solid var(--primary-600);
    }
  }
}

.theme-color {
  // color: var(--primary-600);
}

.gap-x5 {
  gap: 0.5rem;
}

.font-weight-600 {
  font-weight: 600;
}

a {
  color: var(--primary-800);
}

// .mat-card {
//   font-family: "Quicksand", sans-serif !important;
// }

.mat-dialog-container {
  width: 600px !important;
}
.gap-2 {
  gap: 2rem;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary-800) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary-800) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: var(--primary-800) !important;
  background-color: var(--primary-800) !important;
}

.mat-option {
  height: 34px !important;
}

.cursor-pointer {
  cursor: pointer;
}

//  TUI Image Editor CSS
.tui-image-editor-header-logo {
  display: none;
}

.tui-image-editor-container .tui-image-editor-help-menu.top {
  width: 100% !important;
  top: 7px !important;
  height: auto !important;
  display: flex;
  align-items: center;
}

.tui-image-editor-container .tui-image-editor-header-buttons div {
  color: var(--gray30) !important;
  background-color: var(--white) !important;
  border: 1px solid var(--gray30) !important;
  border-radius: 0 !important;
  padding: 6px 20px !important;
  font-size: 14px !important;
  // font-family: "Quicksand", sans-serif !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  line-height: normal !important;
  height: auto !important;
}
.tui-image-editor-container .tui-image-editor-header-buttons div:hover {
  background-color: var(--gray80) !important;
}
.tui-image-editor-container .tui-image-editor-main-container {
  background-color: var(--white) !important;
}

.tui-image-editor-container .tui-image-editor-header-buttons button {
  color: var(--white) !important;
  background-color: var(--primary-800) !important;
  border: 1px solid var(--primary-800) !important;
  border-radius: 0px !important;
  padding: 6px 20px !important;
  font-size: 14px !important;
  // font-family: "Quicksand", sans-serif !important;
  text-transform: uppercase !important;
  line-height: normal !important;
  height: auto !important;
  font-weight: 600 !important;
}
.tui-image-editor-container .tui-image-editor-header-buttons button:hover {
  background-color: var(--primary-800) !important;
}

#imageEditor {
  width: 100% !important;
}

.tui-image-editor-container.bottom .tui-image-editor-submenu > div {
  background-color: var(--dark) !important;
}

.tui-image-editor-container .tui-image-editor-controls {
  background-color: var(--primary-800) !important;
}

app-picture-element {
  .mat-drawer-content {
    overflow: visible;
  }
  .drawerStyling {
    .mat-drawer-container {
      .mat-drawer-content {
        z-index: 5;
      }
    }
  }
}

app-linked-module {
  .mat-select-arrow-wrapper {
    margin-top: -16px !important;
    margin-right: 6px !important;
  }
  .mat-select-value {
    color: var(--white) !important;
  }
  .search input {
    padding: 6px;
    font-size: 1rem;
    border: 1px solid var(--gray80) !important;
    border-radius: 0px 4px 4px 0px;
    width: 98% !important;
    text-align: left;
  }
}

.example-margin {
  .mat-checkbox-layout .mat-checkbox-label {
    font-weight: 800 !important;
  }
}

.mat-select-panel {
  padding: 14px !important;
}

.dt mat-drawer-content {
  margin-left: 0 !important;
  position: fixed !important;
  top: auto;
  left: 0;
  right: 0;
  bottom: auto;
}

// tinymace editor

.tox.tox-tinymce {
  border-radius: 4px !important;
  z-index: 9 !important;
}
.tox.tox-tinymce.tox-fullscreen {
  z-index: 19 !important;
  top: 7% !important;
}

.tox {
  .tox-statusbar,
  .tox-editor-container div[role='group'],
  .tox-menu {
    background-color: var(--gray20) !important;
  }

  .tox-toolbar__group {
    padding: 0 2px 0 2px !important;
    border-right: 1px solid var(--gray50) !important;
  }

  .tox-editor-header {
    padding: 0px !important;
  }

  .tox-collection--list .tox-collection__item--active,
  // .tox-selectfield select,
  .tox-button--naked[disabled],
  .tox-tbtn--enabled,
  .tox-tbtn--enabled:hover,
  .tox-tbtn:hover {
    background-color: var(--primary-600) !important;
    color: var(--text-color) !important;
  }

  .tox-dialog__header {
    background-color: var(--primary-600) !important;
  }

  // .tox-edit-area__iframe {
  //   background-color: var(--white) !important;
  // }

  .tox-dialog__header {
    padding: 8px 16px 8px 16px !important;
  }

  .tox-button--secondary,
  .tox-dialog__footer-end button[title='Close'] {
    color: var(--gray30) !important;
    background-color: var(--white) !important;
    border: 1px solid var(--gray30) !important;
    border-radius: 0 !important;
    padding: 6px 20px !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
  }

  .tox-dialog,
  .tox-dialog__footer {
    background-color: var(--white) !important;
    border-top: none !important;
  }

  .tox-dialog__body-nav-item,
  .tox-dialog__body {
    color: var(--dark) !important;
  }

  .tox-dialog__body-nav-item--active {
    border-bottom: var(--primary-800) !important;
    color: var(--primary-800) !important;
  }

  button[title='Save'],
  button[title='Find'] {
    color: var(--text-color) !important;
    background-color: var(--primary-600) !important;
    border: 1px solid var(--primary-600) !important;
    border-radius: 0px !important;
    padding: 6px 20px !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
  }

  .tox-label,
  .tox-toolbar-label,
  .tox-collection__item {
    color: var(--dark) !important;
  }

  // div[role='menuitemcheckbox'],
  // div[role='menuitem'],
  .tox-button--naked {
    background-color: var(--primary-600) !important;
    color: var(--text-color) !important;
  }

  .tox-listboxfield .tox-listbox--select,
  .tox-textarea,
  .tox-textfield,
  .tox-toolbar-textfield {
    background-color: var(--white) !important;
    color: var(--dark) !important;
  }

  .tox-tbtn--enabled svg,
  .tox-tbtn--enabled:hover svg,
  .tox-tbtn:hover svg {
    fill: var(--text-color) !important;
  }

  // button[title='Constrain proportions'] {
  //   svg {
  //     fill: var(--dark) !important;
  //   }
  //   &:hover:not(:disabled),
  //   &:focus:not(:disabled) {
  //     background-color: mat.get-color-from-palette($app-accent, 100, 0.2) !important;
  //   }
  // }
  .tox-dialog__title {
    font-size: 15px !important;
    color: var(--text-color) !important;
  }
}

.stepdisplaytext p img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}
