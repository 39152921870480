/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '~ng-pick-datetime/assets/style/picker.min.css';
@import '~@ng-select/ng-select/themes/default.theme.css';

// @include mat.core();
// $app-primary: mat.define-palette(mat.$indigo-palette);
// $app-accent: mat.define-palette(mat.$pink-palette);
// $app-warn: mat.define-palette(mat.$red-palette);

// Custom Sass colors vars (will be available in all the project)
// $primary: mat.get-color-from-palette($app-primary);
// $accent: mat.get-color-from-palette($app-accent);
// $warn: mat.get-color-from-palette($app-warn);

// $theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

// @include mat.all-component-themes($theme);

// Using the $theme variable from the pre-built theme you can call the theming function

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import 'node_modules/ngx-toastr/toastr';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $saas-admin-primary: mat.define-palette(mat.$indigo-palette);
// $saas-admin-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $saas-admin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
// $saas-admin-theme: mat.define-light-theme($saas-admin-primary, $saas-admin-accent, $saas-admin-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

// @include mat.all-component-themes($saas-admin-theme);
// @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import './scss/component-overwrite';
@import './scss/combine';
@import './scss/font';
@import './scss/modal';
@import './scss/main';
// @font-face {
//   src: url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'),
//     url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'),
//     url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'),
//     url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
//   font-family: 'Quicksand', sans-serif;
//   font-weight: 600;
// }
@import './assets/scss-lms/font-awesome/scss/fontawesome.scss';
@import './assets/scss-lms/font-awesome/scss/solid.scss';
@import './assets/scss-lms/font-awesome/scss/regular.scss';
@import './assets/scss-lms/theme/theme';
// @import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700&display=swap');
@import './assets/scss-lms/_material-components-fonts';
// @font-face {
//   font-family: 'Arboria';
//   src: url('./assets/fonts/Arboria-Medium.ttf');
//   font-weight: 600;
// }

$custom-typography: mat.define-typography-config(
  $font-family: 'Quicksand'
);

@include mat.core($custom-typography);

* {
  font-family: 'Quicksand', sans-serif;
  // font-family: 'Arboria', sans-serif;
  font-weight: 500;
  // --white: #fff !important
}

html,
body {
  // height: 100%;
  background-color: var(--white);
  font-family: 'Quicksand', sans-serif;
  // font-family: 'Arboria', sans-serif;
  color: var(--secondary);
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  position: relative;
  overflow: auto;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4,
.mat-typography h5,
.mat-typography h6 {
  font-family: 'Quicksand', sans-serif !important;
}

// body {
//   margin: 0;
//   // font-family: 'Quicksand', sans-serif;
// }

// html,
// body {
//   height: 100%;
// }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.version-badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: var(--white);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: var(--gray60);
  border-radius: 10px;
  margin: 0px 5px;
}

.red-snackbar {
  background-color: var(--danger) !important;
  color: var(--white);
  // background-color: rgb(153, 50, 50);
}

.green-snackbar {
  background-color: var(--success) !important;
  // background-color: #69885d;
  color: var(--white) !important;

  .mat-button-wrapper {
    color: var(--white) !important;
  }
}

.note-dropzone {
  display: none !important;
}

.help-tooltip {
  padding: 1rem 1.5rem;
  border-radius: 4px;
  color: var(--gray30);
  border: 1px solid var(--gray20);
  box-shadow: 0px 3px 12px var(--gray80);
  background-color: var(--white);
  font-size: 11px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
  flex-flow: column;
  min-width: 350px;
  text-align: left;
  // font-family: 'Quicksand', sans-serif;
  font-weight: 500;

  ul {
    padding-left: 1.5rem;
  }
}

.pointer {
  cursor: pointer;
}

.fileName {
  font-weight: 700;
  // color: red;
}

label {
  font-weight: 600;
  // font-family: 'Quicksand', sans-serif;
}

.dt-head {
  margin: 10px 0px !important;
  font-weight: 600 !important;
  color: var(--secondary) !important;
  font-size: 25px !important;
}

.head {
  margin: 10px 0px !important;
  font-weight: 700 !important;
  color: var(--secondary) !important;
  font-size: 30px !important;
}

.mat-expansion-panel-body {
  padding: 0 24px 0 !important;
  width: 100% !important;
}

.mat-expansion-panel {
  background-color: transparent !important;
}

.mat-drawer-container {
  background: var(--white) !important;
  border-radius: 0px !important;
}

.mat-drawer-inner-container {
  background: var(--white) !important;
}

.card-body {
  background: var(--white) !important;
}

.spinner {
  position: absolute;
  margin: auto;
  text-align: center;
  display: flex;
  width: 100%;
  top: 45%;
  justify-content: center;
  z-index: 9999;
  // height: 70vh;
  // overflow: hidden;
}

/* Decision Tree org chart html start */

.outer_chart_container {
  height: 480px !important;
}

.add_url_name {
  margin-bottom: 25px !important;
}

.add-menu-icon {
  /*margin-right: -14px !important;
  top: -12px !important;
  right: 34px !important;
  position: absolute !important;
  cursor: pointer !important;*/
}

.delete-node-icon {
  /*color: red !important;
  top: -12px !important;
  right: 4px !important;
  cursor: pointer !important;
  position: absolute !important;*/
}

.start-link-node-icon {
  /*margin-right: 2px !important;
  top: -12px !important;
  right: 34px !important;
  position: absolute !important;
  cursor: pointer !important;*/
  display: block !important;
}

.start-link-node-icon-hide {
  /*margin-right: 2px !important;
  top: -12px !important;
  right: 34px !important;
  position: absolute !important;
  cursor: pointer !important;*/
  display: none !important;
}

.end-link-node-icon {
  /*margin-right: 2px !important;
  top: -12px !important;
  right: 34px !important;
  position: absolute !important;
  cursor: pointer !important;*/
  display: block !important;
}

.end-link-node-icon-hide {
  /*margin-right: 2px !important;
  top: -12px !important;
  right: 34px !important;
  position: absolute !important;
  cursor: pointer !important;*/
  display: none !important;
}

.unlink-node-icon {
  /*margin-right: 2px !important;
  top: -12px !important;
  right: 34px !important;
  position: absolute !important;*/
  display: block !important;
  /*cursor: pointer !important;*/
  color: var(--danger) !important;
}

.unlink-node-icon-hide {
  /*margin-right: 2px !important;
  top: -12px !important;
  right: 34px !important;
  position: absolute !important;
  cursor: pointer !important;*/
  display: none !important;
}

.edit-icon {
  /*margin-right: 2px !important;
  top: -12px !important;
  left: 6px !important;
  position: absolute !important;
  cursor: pointer !important;*/
}

.direct-icon {
  margin-right: -10px !important;
  top: 23px !important;
  position: absolute !important;
  cursor: pointer !important;
  left: 9px !important;
  color: var(--white) !important;
  font-size: 8px !important;
}

.menu-icon {
  top: 8px !important;
  position: absolute !important;
  cursor: pointer !important;
  left: 8px !important;
  color: var(--white) !important;
  font-size: 8px !important;
}

.orgchart .dropdown {
  background: var(--primary) !important;
  padding: 20px 12px !important;
  left: 3px !important;
  position: absolute !important;
  top: 0px !important;
  border-radius: 10px 0px 0px 10px !important;
}

.userResponse_title {
  /*background-color: #009933 !important !important;*/
}

.guidanceStep_title {
  /*background-color: #006699 !important !important;*/
}

.filter-node-panel {
  margin: 10px !important;
}

.matched {
  border: 1px solid var(--warning) !important;
}

.note-modal-backdrop {
  display: none !important;
}

.orgchart .node .edge {
  display: block !important;
}

.node-selected {
  /*border: 1px solid #0a5f75 !important !important;*/
  border-color: var(--primary) !important;
  // background: mat.get-color-from-palette($app-accent, A100, 0.2) !important;
}

.titleContent div {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 120px !important;
}

.orgchart .title input[type='text'] {
  border: none !important;
  outline: none !important;
  background: transparent !important;
  height: 17px !important;
}

.userResponse_title {
  border-color: var(--primary-300) !important;
}

.api-command-decision .form-custom .nav-tabs > li.active > a,
.api-command-decision .form-custom .nav-tabs > li.active > a:hover,
.api-command-decision .form-custom .nav-tabs > li.active > a:focus {
  padding-bottom: 39px !important;
}

.section-4-2-help {
  left: 98% !important;
}

.mce-notification {
  display: none !important;
}

/* Decision Tree org chart html end */
.tox-notification--warning .tox-notification--in .tox-notification {
  display: none;
}

.mce-notification {
  display: none !important;
}

svg.leader-line {
  z-index: 1 !important;
  position: fixed;
}

.direction-rtl {
  div > h2.head {
    // float: right;
    display: flex;
  }

  div.d-flex.align-items-center.justify-content-between.mt-4 {
    justify-content: space-evenly !important;
  }

  d-flex.justify-content-between.align-items-center.p-3.shadow.bg-3 > div > h2 {
    text-align: right;
  }

  app-dialog-screen {
    div.mat-select-trigger {
      display: inline-grid;
      align-items: flex-start;
    }

    div.dialog-head.ng-star-inserted {
      flex-direction: row-reverse;
    }

    div.dialog-info {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  app-comformation-dialog {
    div.mat-select-trigger {
      display: inline-grid;
      align-items: flex-start;
    }

    div.dialog-head {
      flex-direction: row-reverse;
    }

    div.dialog-info {
      display: flex;
      flex-direction: row-reverse;
    }

    .mat-dialog-content {
      float: right;
    }

    .link-buttons {
      margin-top: 2rem;
    }
  }

  app-conformation-dialog {
    div.mat-select-trigger {
      display: inline-grid;
      align-items: flex-start;
    }

    div.dialog-head {
      flex-direction: row-reverse;
    }

    div.dialog-info {
      display: flex;
      flex-direction: row-reverse;
    }

    .mat-dialog-content {
      float: right;
    }

    .link-buttons {
      margin-top: 2rem;
    }
  }

  .list-group-item {
    display: flex;
    gap: 5px;
  }

  .mat-expansion-panel-content {
    flex-direction: row;
  }
}

///lms
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/material-icons.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

// * {
//   padding: 0;
//   margin: 0;
//   box-sizing: border-box;
// }

// html,
// body {
//   height: auto;
// }
body {
  margin: 0;
  padding: 0;
}

* {
  font-family: 'Quicksand', sans-serif;

  .mat-form-field {
    line-height: 1.2rem;
  }
}

.quiz-dialog-modal {
  .mat-dialog-container {
    padding: 0px;
    border-radius: 0px;
  }
}

.question-choices-single {
  .question-choices {
    .mat-radio-label {
      padding: 12px;
      box-sizing: border-box;

      // color: mat-color($app-primary-palette, 500);
      .mat-radio-label-content {
        white-space: pre-line;
        margin-left: 8px;
      }
    }
  }
}

.question-choices-multiple {
  .question-choices {
    .mat-checkbox-layout {
      .mat-checkbox-label {
        white-space: pre-line;
      }
    }
  }
}

:root {
  --a-color: rgba(0, 0, 0, 0.8);
  --p-color: rgba(0, 0, 0, 0.7);
  --s-color: #0a5f75;
  --t-color: #f5f5f5;
  --b-color: rgba(0, 0, 0, 0.1);
  --text-color: rgba(0, 0, 0, 1);
  --white-color: #fafafa;
}

#imageEditor {
  span {
    font-size: 15px;
    color: #054358 !important;
  }

  label {
    span {
      position: relative;
      top: 3px !important;
    }
  }

  div {
    label {
      line-height: 20px;
    }
  }

  div.FIE_text-font-family-option {
    label {
      position: relative;
      top: 3px;
    }
  }

  .FIE_topbar-save-button {
    font-size: 20px;
    background-color: #054358 !important;
    color: white !important;

    span {
      color: white !important;
    }
  }

  label.SfxButton-Label {
    background-color: #054358 !important;
    color: white !important;
  }

  span.sc-lxwit0-0 {
    background-color: #054358 !important;
    color: white !important;
  }
}

.gNLRZt {
  width: 94px;
  height: 36px;

  span {
    font-size: 20px;
  }
}

button[color='error'] {
  background: #054358 !important;
  color: white !important;
}

.SfxButton-Label {
  font-family: sans-serif !important;
}

.SfxModalActions-root {
  button[color='primary'] {
    background: #054358 !important;
    color: white !important;
  }

  button[color='link'] {
    color: #054358 !important;
  }
}

.undo-height {
  height: 28px !important;
  width: 52px !important;
}

.redo-height {
  height: 28px !important;
  width: 30px !important;
}

.save-button-text {
  font-size: 16px !important;
}

.save-button {
  height: 35px !important;
  font-size: 15px !important;
  width: 18px !important;
}

.history-elements {
  margin: 0px 13px !important;
  column-gap: 0px !important;
}

.SfxModalTitle-LabelPrimary {
  font-family: sans-serif !important;
}

.cdk-global-scrollblock {
  overflow-y: hidden !important;
}

::-webkit-scrollbar {
  display: block;
  width: 16px;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-track {
  background-color: #00000000;
}

*::-webkit-scrollbar-track-piece {
  background-color: #00000000;
}

*::-webkit-scrollbar-thumb {
  transition: background-color 0.5s;
  background-color: #00000000;
  border: 5px solid transparent;
  border-radius: 24px;
  box-shadow: 4px 0px 0px 4px #00000040 inset;
}

del {
  // color: transparent !important;
  // background-color: rgba(0, 0, 0, 0.05);
  visibility: hidden;
}

del:has(img) {
  visibility: hidden;
}

.oldArticle {
  ins {
    background-color: #e0fcdc !important;
    text-decoration: none;
  }
}

.newArticle {
  ins {
    text-decoration: none;
    background-color: aqua !important;
  }
}

app-self-service-module {
  .mat-select-arrow-wrapper {
    margin-top: -16px !important;
    margin-right: 6px !important;
  }

  .mat-select-value {
    color: var(--white) !important;
  }

  .search input {
    padding: 6px;
    font-size: 1rem;
    border: 1px solid var(--gray80) !important;
    border-radius: 0px 4px 4px 0px;
    width: 98% !important;
    text-align: left;
  }
}

.mat-paginator-container {
  background: inherit !important;
}

.mat-paginator {
  background: var(--white) !important;
  width: 100% !important;
}

.viewport {
  height: 300px;
}

.select-panel {
  max-height: 300px;
}

.virtual-scroll-viewport {
  height: 200px;
  width: 100%;
}

.mat-select-panel {
  max-height: 300px !important;
}

mat-drawer .mat-drawer-translation {
  width: 45% !important;
}
mat-drawer .mat-drawer-selfservice {
  width: 45% !important;
}
.cdk-virtual-scroll-content-wrapper {
  min-height: 100% !important;
  width: 100%;
}

angular-tag-cloud a {
  opacity: inherit !important;
}

angular-tag-cloud span {
  opacity: inherit !important;
}

angular-tag-cloud .tooltiptext {
  z-index: 1999 !important;
}

.viewport {
  height: 300px;
}

.select-panel {
  max-height: 300px;
}

.virtual-scroll-viewport {
  height: 200px;
  width: 100%;
}

.mat-select-panel {
  max-height: 300px !important;
}

.cdk-virtual-scroll-content-wrapper {
  min-height: 100% !important;
  width: 100%;
}

.search-config-select .mat-select-value {
  width: auto !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content-sub-sections .mat-checkbox-label {
  max-width: 100px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}
@import url(./scss/direction-rtl.scss);

.dynamic .mat-form-field-wrapper {
  margin-bottom: -1.25em !important;
  width: 600px;
}

.full-width .mat-form-field-wrapper {
  width: 100%;
}
.w-35 {
  width: 35%;

  .mat-form-field-wrapper {
    width: 100%;
  }
}

.w-60 {
  width: 60%;
  .mat-form-field-wrapper {
    width: 100%;
  }
}

.owl-dt-container-info .owl-dt-container-info-active,
.owl-dt-container-buttons,
.owl-dt-timer-hour12 {
  color: var(--s-color) !important;
}

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:focus .owl-dt-control-button-content,
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:hover .owl-dt-control-button-content,
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background: var(--s-color) !important;
}

.bg-option-danger {
  background-color: #f44336 !important;
}

.bg-option-success {
  background-color: #28a745 !important;
}

.hidden {
  visibility: hidden;
}

.question_title p,
.quiz-form .mat-typography p {
  margin-bottom: 0 !important;
}

.mat-typography p {
  margin-bottom: unset !important;
}

.flex-grow-4 {
  flex-grow: 4 !important ;
}

:host ::ng-deep .ql-container {
  height: initial;
}
:host[mode='read'] ::ng-deep .ql-container.ql-snow {
  border: none;
}
:host[mode='read'] ::ng-deep .ql-toolbar {
  display: none;
}

.mat-typography h1 {
  margin: 0 0 10px !important;
}

.padding-none .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

//  .mat-progress-bar{
//   position: absolute  !important;
//   z-index: 2  !important;
//  }

.bg-white .mat-paginator-container {
  background: #fff !important;
}

.btn-primary mat-icon {
  color: var(--white);
}
